.active {
    font-weight: 900;
}
 .drawerActive{
    width: 100%; 
    transition:all ease-in-out;
    transform: translateY(50%);
    transition-duration: 500ms; 
    margin-top: 50vh;
    scroll-behavior: smooth;
    overflow: auto;
 }
 .drawerClosed{ 
    width: 100%; 
    transition:all ease-in-out;
    transition-duration: 500ms;
    transform: translateY(0%);  
    scroll-behavior: smooth;
    margin-top: 0vh;
    overflow: auto;
 } 