.active {
    font-weight: 900;
    color:whitesmoke;
}
 .drawerActive{
    width: 100%;
    height: 100%; 
    transition:all ease-in-out;
    transform: translateX(0);
    transition-duration: 750ms;
    z-index: 10; 
 }
 .drawerClosed{
    height: 0%;
    width: 0%; 
    transition:all ease-in-out;
    transition-duration: 750ms;
    transform: translateX(-100%);
    transition-delay: 750ms;
    z-index: 10; 
 }
 .drawerActiveDrop{
    width: 80%;
    height: auto; 
    
    min-height: 60vh; 
    border-radius: 2px;
    transition:all ease-in-out;
    transform: translate(12.7%,5%);
    transition-delay: 150ms;
    transition-duration: 600ms;
    opacity: 1;
    z-index: 10; 
 }
 .drawerClosedDrop{
   width: 0%;
   height: auto;  
   opacity: 0;
    transition:all ease-in-out;
    transition-duration: 600ms; 
    transform: translate(-10%,-100%);
    transition-delay: 750ms;
    z-index: 10; 
 }
 @media screen and (min-width:700px){
   .drawerActive{ 
      transform: translateX(-55%); 
   }
 }
 .hoverGrow{
   transition:all 500ms ease-in-out;
   transition-delay: 500ms; 
   height: 50vh  ;
   min-height: 400px;
 }
 .hoverShrink{
   transition:all 500ms ease-in-out; 
   height: 0px;
   transition-delay: 500ms; 
   min-height: 0px;
 } 