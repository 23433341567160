.my-node-enter {
    opacity: 0;
  }
  .my-node-enter-active {
    opacity: 1;
    transition: opacity 2s;
  }
  .my-node-on-entered{
    opacity: 0;
    transition: opacity 2s;
  }
  .my-node-exit {
    opacity: 1;
  }
  .my-node-exit-active {
    opacity: 0;
    transition: opacity 2s;
  }
  .vl {
    border-left: 4px solid rgba(0, 168, 224,0.85);
    height: 300px;
    margin-right: 10px; 
  }
  .hl {
    border-top: 4px solid rgba(0, 168, 224,0.85);
    width: 80vw;
    margin: 10px;
    box-shadow:0px -1px 1px rgba(0, 168, 224,0.85);
  }
  .vlvert {
    border-left: 4px solid whitesmoke;
    height: 300px;
    margin-right: 10px; 
  }
  .hlvert {
    border-top: 4px solid whitesmoke;
    width: 80vw;
    margin: 10px;
    box-shadow:0px 1px 1px whitesmoke;
  }
  .hooksMain > div {
    position: absolute;
    will-change: transform;
    border-radius: 50%;
    background: rgba(0, 168, 224,0.85);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    opacity: 0.6;
  }
  
  .hooksMain > div:nth-child(1) {
    width: 60px;
    height: 60px;
  }
  
  .hooksMain > div:nth-child(2) {
    width: 125px;
    height: 125px;
  }
  
  .hooksMain > div:nth-child(3) {
    width: 75px;
    height: 75px;
  }
  .container {
    background-color: white;
    min-height: 100vh;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    background-repeat: no-repeat;
    background-size:cover ;
    background-image: url('https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/AT%26T_Globe_2016.svg/1200px-AT%26T_Globe_2016.svg.png');
    z-index: 50;
  }
  .hooksMain > div::after {
    content: '';
    position: absolute;
    top: 20px;
    left: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%; 
  }
  
  .hooksMain > div:nth-child(2)::after {
    top: 35px;
    left: 35px;
    width: 35px;
    height: 35px;
  }
  
  .hooksMain > div:nth-child(3)::after {
    top: 25px;
    left: 25px;
    width: 25px;
    height: 25px;
  }
  
  .hooksMain {
    position: absolute;
    width: 100%;
    min-height: 100%;
    filter: url('#goo');
    overflow: hidden;
    background: transparent; 
    cursor: default;
  }
  .nonanimate{ 
    
    opacity: 0;
    background-color: transparent;
    width: 100vw; 
    height: 0px; 
    visibility: hidden;  
    overflow: hidden;
    position: relative; 
    transition-delay: 500ms; 
    transform:   translateY(-20vh); 
}
.animate{  
  opacity: 1;
  background-color: transparent;
  overflow: hidden;
  width: 100vw;
  position: relative;
  height: 80vh;   
  visibility: visible;
  transform:   translateY(0vw); 
  transition-delay: 500ms; 
  transition: all 5500ms ease-in-out; 
}
.nonanimateslide{ 
  transition-duration: 1000ms;
  transition: all 1000ms ease-in-out;    
  transform: translateX(-30vw); 
  opacity: 0; 
}
.animateslide{   
  opacity: 1;  
transform: translateX(0vw);
  transition-duration: 1000ms; 
  transition: all 1000ms ease-in-out; 
}